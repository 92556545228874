import dashboard from './dashboard';
import comingSoon from './coming_soon';
import swap from './swap';
import resources from './resources';
import other from './other';
import nfts from './nfts';
import mysteryBoxes from './mystery_boxes';
import phenixVault from './phenix_vault';
import icoAndDrops from './ico_drops';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, swap, icoAndDrops, phenixVault, mysteryBoxes, nfts, comingSoon, other, resources]
};

export default menuItems;
