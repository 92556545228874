import PropTypes from 'prop-types';

import { useState } from 'react';
import { ethers } from 'ethers';
import { Link } from 'react-router-dom';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    ButtonBase,
    Button,
    IconButton,
    Chip,
    Stack,
    Menu,
    MenuItem,
    ListItemIcon,
    Tooltip,
    Divider,
    Typography
} from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import Blockies from 'react-blockies-image';

// assets
import { IconMenu2, IconSettings, IconKey, IconUnlink, IconDiamond, IconColorSwatch, IconFlame } from '@tabler/icons';

// settings

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({
    handleLeftDrawerToggle,
    setWallet,
    wallet,
    address,
    setIsShowing,
    setAddress,
    isShowing,
    isAdmin,
    testnetMode,
    accountBalance,
    userHoldings,
    chainInformation
}) => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    [theme.breakpoints.down('md')]: {
                        justifyContent: 'space-between'
                    }
                }}
            >
                <Box sx={{ display: 'flex' }}>
                    <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, width: '200px' }}>
                        <LogoSection />
                    </Box>
                    <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                ...theme.typography.commonAvatar,
                                ...theme.typography.mediumAvatar,
                                transition: 'all .2s ease-in-out',
                                background: theme.palette.secondary.light,
                                color: theme.palette.secondary.dark,
                                '&:hover': {
                                    background: theme.palette.secondary.dark,
                                    color: theme.palette.secondary.light
                                }
                            }}
                            onClick={handleLeftDrawerToggle}
                            color="inherit"
                        >
                            <IconMenu2 stroke={1.5} size="1.3rem" />
                        </Avatar>
                    </ButtonBase>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    {testnetMode ? <Chip label="TESTNET" variant="outlined" /> : ''}

                    {wallet != null && address !== null && chainInformation !== null ? (
                        <Tooltip style={{ cursor: 'pointer' }} title="Account Menu">
                            <Stack onClick={handleClick}>
                                <Chip
                                    label={`${address.toString().substring(0, 4)}..${address
                                        .toString()
                                        .substring(address.length - 4, address.length)}`}
                                    variant="outlined"
                                    size="large"
                                    avatar={<Avatar alt={chainInformation.chainName} src={chainInformation.chainLogo} />}
                                    color="warning"
                                />
                            </Stack>
                        </Tooltip>
                    ) : (
                        <Button
                            onClick={() => {
                                if (wallet === null) {
                                    // testConnection();
                                    setIsShowing(!isShowing);
                                } else {
                                    setWallet(null);
                                }
                            }}
                            size="medium"
                            variant="outlined"
                            sx={{ marginLeft: 'auto' }}
                        >
                            {wallet != null && address !== null
                                ? `${address.toString().substring(0, 4)}..${address
                                      .toString()
                                      .substring(address.length - 4, address.length)}`
                                : 'Connect Wallet'}
                        </Button>
                    )}

                    {address !== null && wallet !== null && chainInformation !== null ? (
                        <Box>
                            <Tooltip title="Account Menu">
                                <IconButton onClick={handleClick}>
                                    <Blockies seed={address.toLowerCase()} scale={4.5} />
                                </IconButton>
                            </Tooltip>

                            <Menu
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={open}
                                onClose={handleClose}
                                onClick={handleClose}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1.5,
                                        '& .MuiAvatar-root': {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1
                                        },
                                        '&:before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: 'background.paper',
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndex: 0
                                        }
                                    }
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                <MenuItem>
                                    <Avatar src={chainInformation.chainLogo} />{' '}
                                    {accountBalance !== null
                                        ? ethers.utils.commify(parseFloat(ethers.utils.formatEther(accountBalance)).toFixed(2)) +
                                          ' ' +
                                          chainInformation.coinSymbol
                                        : 'Loading...'}
                                </MenuItem>
                                <MenuItem>
                                    <Avatar src="/resources/images/logo-new.jpg" />{' '}
                                    {userHoldings !== null
                                        ? ethers.utils.commify(parseFloat(ethers.utils.formatEther(userHoldings)).toFixed(2)) + ' PHNX'
                                        : 'Loading...'}
                                </MenuItem>
                                <Divider variant="middle" sx={{ borderColor: '#aaa' }} />
                                {isAdmin ? (
                                    <MenuItem>
                                        <Link
                                            to="/phenix-core-team"
                                            style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit' }}
                                        >
                                            <ListItemIcon>
                                                <IconKey />
                                            </ListItemIcon>
                                            Admin
                                        </Link>
                                    </MenuItem>
                                ) : (
                                    ''
                                )}
                                <MenuItem>
                                    <Link
                                        to="/swap"
                                        style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit' }}
                                    >
                                        <ListItemIcon>
                                            <IconFlame />
                                        </ListItemIcon>
                                        <Typography>Buy PHNX</Typography>
                                    </Link>
                                </MenuItem>
                                <MenuItem>
                                    <Link
                                        to="/rewards"
                                        style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit' }}
                                    >
                                        <ListItemIcon>
                                            <IconDiamond />
                                        </ListItemIcon>
                                        <Typography>Rewards</Typography>
                                    </Link>
                                </MenuItem>
                                <MenuItem>
                                    <Link
                                        to="/nft"
                                        style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit' }}
                                    >
                                        <ListItemIcon>
                                            <IconColorSwatch />
                                        </ListItemIcon>
                                        My NFTs
                                    </Link>
                                </MenuItem>
                                <Divider variant="middle" sx={{ borderColor: '#aaa' }} />
                                <MenuItem
                                    onClick={() => {
                                        setWallet(null);
                                        setAddress(null);
                                    }}
                                >
                                    <ListItemIcon>
                                        <IconUnlink />
                                    </ListItemIcon>
                                    Disconnect Wallet
                                </MenuItem>
                            </Menu>
                        </Box>
                    ) : (
                        ''
                    )}
                </Box>
            </Box>
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
