export const referralContractAbi = [
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '_referredEthRequirement',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: '_referrerFeeAllocationPercentage',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: '_feePercentageDenominator',
                type: 'uint256'
            }
        ],
        name: 'addReferralLevel',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [],
        name: 'claimEthRewards',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_tokenAddress',
                type: 'address'
            },
            {
                internalType: 'address',
                name: '_routerAddress',
                type: 'address'
            }
        ],
        stateMutability: 'nonpayable',
        type: 'constructor'
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: '_referrerAddress',
                type: 'address'
            },
            {
                indexed: true,
                internalType: 'uint256',
                name: '_claimedEthAmount',
                type: 'uint256'
            }
        ],
        name: 'ClaimEthRewards',
        type: 'event'
    },
    {
        inputs: [],
        name: 'emergencyRecoverEth',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [],
        name: 'emergencyRecoverTokens',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [],
        name: 'joinReferralProgram',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: '_reffererAddress',
                type: 'address'
            }
        ],
        name: 'JoinReferralProgram',
        type: 'event'
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'previousOwner',
                type: 'address'
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'newOwner',
                type: 'address'
            }
        ],
        name: 'OwnershipTransferred',
        type: 'event'
    },
    {
        inputs: [],
        name: 'removeLastReferralLevel',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [],
        name: 'renounceOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_referrerAddress',
                type: 'address'
            },
            {
                internalType: 'uint256',
                name: '_deadline',
                type: 'uint256'
            }
        ],
        name: 'swapExactEthForTokensWithReferral',
        outputs: [],
        stateMutability: 'payable',
        type: 'function'
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: '_referrerAddress',
                type: 'address'
            },
            {
                indexed: true,
                internalType: 'address',
                name: '_buyerAddress',
                type: 'address'
            },
            {
                indexed: true,
                internalType: 'uint256',
                name: '_amountEthSwapped',
                type: 'uint256'
            }
        ],
        name: 'SwapExactEthForTokensWithReferral',
        type: 'event'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'newOwner',
                type: 'address'
            }
        ],
        name: 'transferOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '_percentage',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: '_denominator',
                type: 'uint256'
            }
        ],
        name: 'updateBaseBuyAllocation',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'uint256',
                name: '_percentage',
                type: 'uint256'
            },
            {
                indexed: true,
                internalType: 'uint256',
                name: '_denominator',
                type: 'uint256'
            }
        ],
        name: 'UpdateBaseBuyAllocation',
        type: 'event'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '_minTokens',
                type: 'uint256'
            }
        ],
        name: 'updateMinimumTokensRequired',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'uint256',
                name: '_amountTokens',
                type: 'uint256'
            }
        ],
        name: 'UpdateMinimumTokensRequired',
        type: 'event'
    },
    {
        inputs: [
            {
                internalType: 'bool',
                name: '_state',
                type: 'bool'
            }
        ],
        name: 'updateReferralActiveState',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'bool',
                name: '_state',
                type: 'bool'
            }
        ],
        name: 'UpdateReferralActiveState',
        type: 'event'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '_referralLevel',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: '_referredEthRequirement',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: '_referrerFeeAllocationPercentage',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: '_feePercentageDenominator',
                type: 'uint256'
            }
        ],
        name: 'updateReferralLevelFees',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'uint256',
                name: '_referralLevel',
                type: 'uint256'
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: '_referredEthRequirement',
                type: 'uint256'
            },
            {
                indexed: true,
                internalType: 'uint256',
                name: '_referrerFeeAllocationPercentage',
                type: 'uint256'
            },
            {
                indexed: true,
                internalType: 'uint256',
                name: '_feePercentageDenominator',
                type: 'uint256'
            }
        ],
        name: 'UpdateReferralLevelFees',
        type: 'event'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_routerAddress',
                type: 'address'
            }
        ],
        name: 'updateRouterAddress',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: '_routerAddress',
                type: 'address'
            }
        ],
        name: 'UpdateRouterAddress',
        type: 'event'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_tokenAddress',
                type: 'address'
            }
        ],
        name: 'updateTokenAddress',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: '_tokenAddress',
                type: 'address'
            }
        ],
        name: 'UpdateTokenAddress',
        type: 'event'
    },
    {
        stateMutability: 'payable',
        type: 'receive'
    },
    {
        inputs: [],
        name: 'baseBuyAllocation',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'baseBuyAllocationDenomiator',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '_level',
                type: 'uint256'
            }
        ],
        name: 'getReferralLevelDetails',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '_index',
                type: 'uint256'
            }
        ],
        name: 'getReferrerAddressByIndex',
        outputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_address',
                type: 'address'
            }
        ],
        name: 'getReferrerDetails',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            },
            {
                internalType: 'bool',
                name: '',
                type: 'bool'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'getTotalReferrers',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address'
            },
            {
                internalType: 'address',
                name: '',
                type: 'address'
            }
        ],
        name: 'hasReferred',
        outputs: [
            {
                internalType: 'bool',
                name: '',
                type: 'bool'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'minTokensRequired',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'owner',
        outputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'referralModeActive',
        outputs: [
            {
                internalType: 'bool',
                name: '',
                type: 'bool'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'routerAddress',
        outputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'tokenAddress',
        outputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'totalEthRewards',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'totalEthSwapped',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'totalPendingEthRewards',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'totalReferralLevels',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'totalTokensSwapped',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    }
];
