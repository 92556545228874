export const referralContractV2Abi = [
    {
        inputs: [
            {
                internalType: 'uint256[]',
                name: '_boxIndexes',
                type: 'uint256[]'
            }
        ],
        name: 'claimBoxRewards',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '_count',
                type: 'uint256'
            }
        ],
        name: 'claimERC721Rewards',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [],
        name: 'collectContractFeeAllocation',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_referrer',
                type: 'address'
            },
            {
                internalType: 'uint256',
                name: '_amountTokens',
                type: 'uint256'
            }
        ],
        name: 'handleReferralReward',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_tokenAddress',
                type: 'address'
            },
            {
                internalType: 'address',
                name: '_rewardERC721Address',
                type: 'address'
            },
            {
                internalType: 'address',
                name: '_mysteryBoxAddress',
                type: 'address'
            },
            {
                internalType: 'address',
                name: '_routerAddress',
                type: 'address'
            },
            {
                internalType: 'address',
                name: '_oracleAddress',
                type: 'address'
            }
        ],
        stateMutability: 'nonpayable',
        type: 'constructor'
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'previousOwner',
                type: 'address'
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'newOwner',
                type: 'address'
            }
        ],
        name: 'OwnershipTransferred',
        type: 'event'
    },
    {
        inputs: [],
        name: 'renounceOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_address',
                type: 'address'
            },
            {
                internalType: 'bool',
                name: '_status',
                type: 'bool'
            }
        ],
        name: 'setAdminStatus',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'bool',
                name: '_status',
                type: 'bool'
            }
        ],
        name: 'setPaused',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '_index',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: '_referredUSDRequirement',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: '_referrerFeeAllocationPercentage',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: '_contractFeeAllocationPercentage',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: '_feePercentageDenominator',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: '_rewardERC721Amount',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: '_rewardERC721USDInterval',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: '_rewardBoxAmount',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: '_rewardBoxIndex',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: '_rewardBoxInterval',
                type: 'uint256'
            },
            {
                internalType: 'bool',
                name: '_enabled',
                type: 'bool'
            }
        ],
        name: 'setReferralLevel',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_userAddress',
                type: 'address'
            },
            {
                internalType: 'uint256',
                name: '_index',
                type: 'uint256'
            }
        ],
        name: 'setUserReferralLevel',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: 'amountOut',
                type: 'uint256'
            },
            {
                internalType: 'address[]',
                name: 'path',
                type: 'address[]'
            },
            {
                internalType: 'address',
                name: 'to',
                type: 'address'
            },
            {
                internalType: 'uint256',
                name: 'deadline',
                type: 'uint256'
            },
            {
                internalType: 'address',
                name: 'referrer',
                type: 'address'
            }
        ],
        name: 'swapETHForExactTokensWithReferral',
        outputs: [
            {
                internalType: 'uint256[]',
                name: 'amounts',
                type: 'uint256[]'
            }
        ],
        stateMutability: 'payable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: 'amountOutMin',
                type: 'uint256'
            },
            {
                internalType: 'address[]',
                name: 'path',
                type: 'address[]'
            },
            {
                internalType: 'address',
                name: 'to',
                type: 'address'
            },
            {
                internalType: 'uint256',
                name: 'deadline',
                type: 'uint256'
            },
            {
                internalType: 'address',
                name: 'referrer',
                type: 'address'
            }
        ],
        name: 'swapExactETHForTokensWithReferral',
        outputs: [
            {
                internalType: 'uint256[]',
                name: 'amounts',
                type: 'uint256[]'
            }
        ],
        stateMutability: 'payable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: 'amountIn',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: 'amountOutMin',
                type: 'uint256'
            },
            {
                internalType: 'address[]',
                name: 'path',
                type: 'address[]'
            },
            {
                internalType: 'address',
                name: 'to',
                type: 'address'
            },
            {
                internalType: 'uint256',
                name: 'deadline',
                type: 'uint256'
            },
            {
                internalType: 'address',
                name: 'referrer',
                type: 'address'
            }
        ],
        name: 'swapExactTokensForTokensWithReferral',
        outputs: [
            {
                internalType: 'uint256[]',
                name: 'amounts',
                type: 'uint256[]'
            }
        ],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: 'amountOut',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: 'amountInMax',
                type: 'uint256'
            },
            {
                internalType: 'address[]',
                name: 'path',
                type: 'address[]'
            },
            {
                internalType: 'address',
                name: 'to',
                type: 'address'
            },
            {
                internalType: 'uint256',
                name: 'deadline',
                type: 'uint256'
            },
            {
                internalType: 'address',
                name: 'referrer',
                type: 'address'
            }
        ],
        name: 'swapTokensForExactTokensWithReferral',
        outputs: [
            {
                internalType: 'uint256[]',
                name: 'amounts',
                type: 'uint256[]'
            }
        ],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'newOwner',
                type: 'address'
            }
        ],
        name: 'transferOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        stateMutability: 'payable',
        type: 'fallback'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_erc721Address',
                type: 'address'
            }
        ],
        name: 'updateERC721Address',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_mysteryBoxAddress',
                type: 'address'
            }
        ],
        name: 'updateMysteryBoxAddress',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_oracleAddress',
                type: 'address'
            }
        ],
        name: 'updateOracleAddress',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_referrer',
                type: 'address'
            }
        ],
        name: 'updateReferralBoxRewards',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_referrer',
                type: 'address'
            }
        ],
        name: 'updateReferralERC721Rewards',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_referrer',
                type: 'address'
            }
        ],
        name: 'updateReferrerLevel',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_routerAddress',
                type: 'address'
            }
        ],
        name: 'updateRouterAddress',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_tokenAddress',
                type: 'address'
            }
        ],
        name: 'updateTokenAddress',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_tokenAddress',
                type: 'address'
            },
            {
                internalType: 'uint256',
                name: '_amount',
                type: 'uint256'
            }
        ],
        name: 'withdrawERC20Token',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_erc721Address',
                type: 'address'
            },
            {
                internalType: 'uint256[]',
                name: '_tokenIds',
                type: 'uint256[]'
            }
        ],
        name: 'withdrawERC721Tokens',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '_amount',
                type: 'uint256'
            }
        ],
        name: 'withdrawETH',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        stateMutability: 'payable',
        type: 'receive'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address'
            }
        ],
        name: 'admins',
        outputs: [
            {
                internalType: 'bool',
                name: '',
                type: 'bool'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: 'etherValue',
                type: 'uint256'
            }
        ],
        name: 'convertEthToWei',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        stateMutability: 'pure',
        type: 'function'
    },
    {
        inputs: [],
        name: 'getOraclePhnxPrice',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_address',
                type: 'address'
            },
            {
                internalType: 'uint256[]',
                name: '_indexes',
                type: 'uint256[]'
            }
        ],
        name: 'getPendingBoxRewards',
        outputs: [
            {
                internalType: 'uint256[]',
                name: '',
                type: 'uint256[]'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_address',
                type: 'address'
            }
        ],
        name: 'getPendingERC721Rewards',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: 'phnxAmount',
                type: 'uint256'
            }
        ],
        name: 'getPhnxValueInUsdc',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256[]',
                name: '_levels',
                type: 'uint256[]'
            }
        ],
        name: 'getReferralLevels',
        outputs: [
            {
                components: [
                    {
                        internalType: 'uint256',
                        name: 'referredUSDRequirement',
                        type: 'uint256'
                    },
                    {
                        internalType: 'uint256',
                        name: 'referrerFeeAllocationPercentage',
                        type: 'uint256'
                    },
                    {
                        internalType: 'uint256',
                        name: 'contractFeeAllocationPercentage',
                        type: 'uint256'
                    },
                    {
                        internalType: 'uint256',
                        name: 'feePercentageDenominator',
                        type: 'uint256'
                    },
                    {
                        internalType: 'uint256',
                        name: 'rewardERC721Amount',
                        type: 'uint256'
                    },
                    {
                        internalType: 'uint256',
                        name: 'rewardERC721USDInterval',
                        type: 'uint256'
                    },
                    {
                        internalType: 'uint256',
                        name: 'rewardBoxAmount',
                        type: 'uint256'
                    },
                    {
                        internalType: 'uint256',
                        name: 'rewardBoxIndex',
                        type: 'uint256'
                    },
                    {
                        internalType: 'uint256',
                        name: 'rewardBoxUSDInterval',
                        type: 'uint256'
                    },
                    {
                        internalType: 'bool',
                        name: 'enabled',
                        type: 'bool'
                    }
                ],
                internalType: 'struct PhenixReferralProgram.ReferrerLevel[]',
                name: '',
                type: 'tuple[]'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_address',
                type: 'address'
            }
        ],
        name: 'getReferrer',
        outputs: [
            {
                components: [
                    {
                        internalType: 'uint256',
                        name: 'totalReferredUSD',
                        type: 'uint256'
                    },
                    {
                        internalType: 'uint256',
                        name: 'totalReferredTokens',
                        type: 'uint256'
                    },
                    {
                        internalType: 'uint256',
                        name: 'totalReferredRewards',
                        type: 'uint256'
                    },
                    {
                        internalType: 'uint256',
                        name: 'referrerLevel',
                        type: 'uint256'
                    },
                    {
                        internalType: 'uint256',
                        name: 'lastERC721RewardUSD',
                        type: 'uint256'
                    },
                    {
                        internalType: 'uint256',
                        name: 'lastBoxRewardUSD',
                        type: 'uint256'
                    }
                ],
                internalType: 'struct PhenixReferralProgram.Referrer',
                name: '',
                type: 'tuple'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '_index',
                type: 'uint256'
            }
        ],
        name: 'getReferrerLevel',
        outputs: [
            {
                components: [
                    {
                        internalType: 'uint256',
                        name: 'referredUSDRequirement',
                        type: 'uint256'
                    },
                    {
                        internalType: 'uint256',
                        name: 'referrerFeeAllocationPercentage',
                        type: 'uint256'
                    },
                    {
                        internalType: 'uint256',
                        name: 'contractFeeAllocationPercentage',
                        type: 'uint256'
                    },
                    {
                        internalType: 'uint256',
                        name: 'feePercentageDenominator',
                        type: 'uint256'
                    },
                    {
                        internalType: 'uint256',
                        name: 'rewardERC721Amount',
                        type: 'uint256'
                    },
                    {
                        internalType: 'uint256',
                        name: 'rewardERC721USDInterval',
                        type: 'uint256'
                    },
                    {
                        internalType: 'uint256',
                        name: 'rewardBoxAmount',
                        type: 'uint256'
                    },
                    {
                        internalType: 'uint256',
                        name: 'rewardBoxIndex',
                        type: 'uint256'
                    },
                    {
                        internalType: 'uint256',
                        name: 'rewardBoxUSDInterval',
                        type: 'uint256'
                    },
                    {
                        internalType: 'bool',
                        name: 'enabled',
                        type: 'bool'
                    }
                ],
                internalType: 'struct PhenixReferralProgram.ReferrerLevel',
                name: '',
                type: 'tuple'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'mysteryBoxAddress',
        outputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'oracleAddress',
        outputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'owner',
        outputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'paused',
        outputs: [
            {
                internalType: 'bool',
                name: '',
                type: 'bool'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address'
            },
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        name: 'rewardBoxesPending',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'rewardERC721Address',
        outputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address'
            }
        ],
        name: 'rewardERC721Pending',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'routerAddress',
        outputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'tokenAddress',
        outputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    }
];
