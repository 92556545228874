import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import axios from 'axios';
import db from 'firebase';

import CacheBuster from 'react-cache-buster';
import { version } from '../package.json';

// ethers
import { ethers, BigNumber } from 'ethers';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import ConnectWalletModule from 'ui-component/tiles/ConnectWalletModule';
import { rpcNetwork_mainnet, rpcNetwork, mode } from 'utils/walletConnectConfig';

// contract ABIs
import { mysteryBoxContractAbi } from 'utils/contractAbis/mysteryBoxContractAbi';
import { nftContractAbi } from 'utils/contractAbis/nftContractAbi';
import { nftStakingContractAbi } from 'utils/contractAbis/nftStakingContractAbi';
import { referralContractAbi } from 'utils/contractAbis/referralContractAbi';
import { referralContractV2Abi } from 'utils/contractAbis/referralContractV2Abi';
import { tokenContractAbi } from 'utils/contractAbis/tokenContractAbi';
import { ercContractAbi } from 'utils/contractAbis/ercContractAbi';
import { routerContractAbi } from 'utils/contractAbis/routerContractAbi';
import { vestingVaultFactoryAbi } from 'utils/contractAbis/vestingVaultFactoryAbi';

import cronosContractAddresses from 'utils/contractAddresses/cronosContractData';

import { admins } from 'utils/adminAddresses';

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);
    const DEAD = '0x000000000000000000000000000000000000dEaD';
    const USDC = '0xc21223249CA28397B4B6541dfFaEcC539BfF0c59';
    const INIT_CIRCULATING_SUPPLY = 10000000;

    const [wallet, setWallet] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [accountBalance, setAccountBalance] = useState(null);
    const [isShowing, setIsShowing] = useState(false);
    const [address, setAddress] = useState(null);
    const [croPrice, setCroPrice] = useState(null);
    const [tokenPrice, setTokenPrice] = useState(null);
    const [tokenHolders, setTokenHolders] = useState(null);
    // --------
    const [testnetMode, setTestnetMode] = useState(mode == 'regular' ? false : true);
    const [chainInformation, setChainInformation] = useState({
        assetPlatform: 'cronos',
        chainName: 'cronos',
        coinName: 'Cronos',
        coinSymbol: 'CRO',
        coinTxnScanner: 'https://cronoscan.com/tx/',
        chainId: 25,
        chainRpc: 'https://evm-cronos.crypto.org/',
        chainLogo: '/resources/images/cro-logo.svg',
        contractAddresses: cronosContractAddresses,
        nftImageEndpoint: 'https://cdn.ebisusbay.com/QmbxkinoBXpR8BGFbKNCbJsyBGUn6cwgnMagiJXz1nbkKP/',
        raritySniperEndpoint: 'https://raritysniper.com/flaming-phenix-club/',
        ebisusBayMarketplaceEndpoint: 'https://app.ebisusbay.com/collection/flaming-phenix-club/',
        openSeaMarketplaceEndpoint: null,
        nftBannerImage: 'resources/images/bg_op.png',
        coinGeckoIds: {
            eth: 'crypto-com-chain',
            phnx: 'phenix-finance-2'
        },
        buyLink: 'https://mm.finance/swap?outputCurrency=0x91381CED862941aF90995bC48336643a37CDA580'
    });
    // --------
    const [defaultProvider, setDefaultProvider] = useState(null);
    const [routerContract, setRouterContract] = useState(null);
    const [mysteryBoxContract, setMysteryBoxContract] = useState(null);
    const [tokenContract, setTokenContract] = useState(null);
    const [referralContract, setReferralContract] = useState(null);
    const [referralContractV2, setReferralContractV2] = useState(null);
    const [nftContract, setNftContract] = useState(null);
    const [nftStakingContract, setNftStakingContract] = useState(null);
    const [vestingVaultFactoryContract, setVestingVaultFactoryContract] = useState(null);
    // -------
    const [pfrBalance, setPfrBalance] = useState(null);
    const [burnBalance, setBurnBalance] = useState(null);
    const [totalRx3Rewards, setTotalRx3Rewards] = useState(null);
    const [marketCap, setMarketCap] = useState(null);
    const [supply, setSupply] = useState(null);
    const [userHoldings, setUserHoldings] = useState(null);
    const [lastRebaseDelta, setLastRebaseDelta] = useState(null);
    const [lastRebaseTimeStamp, setLastRebaseTimeStamp] = useState(null);
    // -------
    const [ticker, setTicker] = useState(false);

    // fetch token data via firebase
    const fetchDashboardData = () => {
        // var startTime = Math.floor(Date.now()) / 1000;

        if (chainInformation !== null && tokenContract !== null) {
            axios.get('https://us-central1-phenix-app-881db.cloudfunctions.net/getDashboardData').then((_result) => {
                const data = _result.data;
                setPfrBalance(data.pfrValue);
                setBurnBalance(data.totalBurned);
                setTotalRx3Rewards(data.totalRX3Rewards);
            });

            const ids = [];
            const hasEthId = chainInformation.coinGeckoIds.eth !== undefined && chainInformation.coinGeckoIds.eth.length > 0;
            const hasPhnxId = chainInformation.coinGeckoIds.phnx !== undefined && chainInformation.coinGeckoIds.phnx.length > 0;

            if (hasEthId) {
                ids.push(chainInformation.coinGeckoIds.eth);
            }

            if (hasPhnxId) {
                ids.push(chainInformation.coinGeckoIds.phnx);
            }

            var currentIdIndex = 0;
            axios
                .get('https://api.coingecko.com/api/v3/simple/price?ids=' + ids.join(',') + '&vs_currencies=usd')
                .then((_prices) => {
                    if (hasEthId) {
                        setCroPrice(_prices.data[chainInformation.coinGeckoIds.eth].usd);
                        currentIdIndex++;
                    } else {
                        setCroPrice(0);
                    }
                })
                .catch((_err) => {
                    console.log('Failed to fetch token prices from CoinGecko!');
                });
        }
    };

    useEffect(() => {
        if (routerContract !== null && chainInformation !== null) {
            routerContract.WETH().then((weth) => {
                routerContract
                    .getAmountsOut(ethers.utils.parseEther('1').toString(), [weth, chainInformation.contractAddresses.tokenContractAddress])
                    .then((out) => {
                        var amountTokenOut = parseFloat(ethers.utils.formatEther(out[1].toString()));
                        const _tokenPrice = parseFloat(croPrice / amountTokenOut);
                        setTokenPrice(_tokenPrice);

                        // get token supply
                        tokenContract.balanceOf(DEAD).then((balance) => {
                            const totalBurned = ethers.utils.formatEther(balance.toString());
                            setBurnBalance(totalBurned * tokenPrice);
                            tokenContract.getCirculatingSupply().then((_supply) => {
                                const circulatingSupply = parseFloat(ethers.utils.formatEther(_supply.toString()));
                                setSupply(circulatingSupply);

                                // get total rx3 rewards
                                setTotalRx3Rewards((circulatingSupply - (INIT_CIRCULATING_SUPPLY - totalBurned)) * _tokenPrice);

                                // set market cap
                                setMarketCap(circulatingSupply * _tokenPrice);
                            });
                        });
                    });
            });
        }
    }, [routerContract, chainInformation, wallet, ticker]);

    useEffect(() => {
        if (tokenContract !== null && tokenPrice !== null && tokenPrice !== 0 && !testnetMode) {
            // get last rebase info
            tokenContract.lastRebaseTimestamp().then((lastTimestamp) => {
                setLastRebaseTimeStamp(lastTimestamp.toString());
            });

            tokenContract.lastRebaseDelta().then((lastDelta) => {
                var lastChange = ethers.utils.formatEther(lastDelta.toString());
                setLastRebaseDelta(lastChange);
            });
        }
    }, [tokenPrice, ticker, tokenPrice]);

    useEffect(() => {
        setTimeout(() => {
            setTicker(!ticker);
        }, 5000);
    }, [ticker]);

    useEffect(() => {
        if (wallet !== null && tokenContract !== null && address !== null) {
            // set user holders
            tokenContract.balanceOf(address).then((balance) => {
                setUserHoldings(balance.toString());
            });
        }

        if (wallet !== null) {
            wallet.provider.getBalance(wallet.getAddress()).then((_balance) => {
                setAccountBalance(_balance.toString());
            });
        }

        if (address !== null && wallet !== null) {
            setIsAdmin(admins.includes(address.toLowerCase()));
        } else {
            setIsAdmin(false);
        }
    }, [wallet, tokenContract, address, ticker]);

    // Contract Initialization
    useEffect(() => {
        if (wallet !== null && chainInformation !== null && chainInformation.contractAddresses !== null) {
            // Router Contract
            if (chainInformation.contractAddresses.routerContractAddress !== undefined) {
                setRouterContract(new ethers.Contract(chainInformation.contractAddresses.routerContractAddress, routerContractAbi, wallet));
            } else {
                setRouterContract(null);
            }

            // Token Contract
            if (chainInformation.contractAddresses.tokenContractAddress !== undefined) {
                setTokenContract(new ethers.Contract(chainInformation.contractAddresses.tokenContractAddress, tokenContractAbi, wallet));
            } else {
                setTokenContract(null);
            }

            // Referral Contract
            if (chainInformation.contractAddresses.referralContractAddress !== undefined) {
                setReferralContract(
                    new ethers.Contract(chainInformation.contractAddresses.referralContractAddress, referralContractAbi, wallet)
                );
            } else {
                setReferralContract(null);
            }

            // Referral Contract V2
            if (chainInformation.contractAddresses.referralContractV2Address !== undefined) {
                setReferralContractV2(
                    new ethers.Contract(chainInformation.contractAddresses.referralContractV2Address, referralContractV2Abi, wallet)
                );
            } else {
                setReferralContractV2(null);
            }

            // NFT Contract
            if (chainInformation.contractAddresses.nftContractAddress !== undefined) {
                setNftContract(new ethers.Contract(chainInformation.contractAddresses.nftContractAddress, nftContractAbi, wallet));
            } else {
                setNftContract(null);
            }

            // NFT Staking Contract
            if (chainInformation.contractAddresses.nftStakingContractAddress !== undefined) {
                setNftStakingContract(
                    new ethers.Contract(chainInformation.contractAddresses.nftStakingContractAddress, nftStakingContractAbi, wallet)
                );
            } else {
                setNftStakingContract(null);
            }

            // Mystery Box Contract
            if (chainInformation.contractAddresses.mysteryBoxContractAddress !== undefined) {
                setMysteryBoxContract(
                    new ethers.Contract(chainInformation.contractAddresses.mysteryBoxContractAddress, mysteryBoxContractAbi, wallet)
                );
            } else {
                setMysteryBoxContract(null);
            }

            // Vesting Vault Factory Contract
            if (chainInformation.contractAddresses.vestingVaultFactory !== undefined) {
                setVestingVaultFactoryContract(
                    new ethers.Contract(chainInformation.contractAddresses.vestingVaultFactory, vestingVaultFactoryAbi, wallet)
                );
            } else {
                setVestingVaultFactoryContract(null);
            }
        }
    }, [chainInformation, wallet]);

    useEffect(() => {
        fetchDashboardData();
    }, [tokenContract, routerContract]);

    useEffect(() => {
        if (defaultProvider === null && false) {
            const _defaultProvider = new ethers.providers.JsonRpcProvider(
                mode == 'regular' ? rpcNetwork_mainnet.rpcUrl : rpcNetwork.rpcUrl
            );
            setDefaultProvider(_defaultProvider);
        }

        // get holders
        fetch(
            'https://cronos.org/explorer/api?module=token&action=getTokenHolders&contractaddress=0x91381CED862941aF90995bC48336643a37CDA580&offset=10000'
        )
            .then((response) => response.json())
            .then((data) => {
                setTokenHolders(data.result.length);
            });

        fetchDashboardData();
    }, []);

    return (
        <CacheBuster currentVersion={version} isEnabled={true} isVerboseMode={false}>
            <>
                <ConnectWalletModule
                    setAccountBalance={setAccountBalance}
                    isShowing={isShowing}
                    setIsShowing={setIsShowing}
                    wallet={wallet}
                    setWallet={setWallet}
                    setAddress={setAddress}
                    setChainInformation={setChainInformation}
                    defaultProvider={defaultProvider}
                />
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={themes(customization)}>
                        <CssBaseline />
                        <NavigationScroll>
                            <Routes
                                wallet={wallet}
                                setWallet={setWallet}
                                accountBalance={accountBalance}
                                setAccountBalance={setAccountBalance}
                                setIsShowing={setIsShowing}
                                isShowing={isShowing}
                                address={address}
                                setAddress={setAddress}
                                tokenPrice={tokenPrice}
                                croPrice={croPrice}
                                pfrBalance={pfrBalance}
                                burnBalance={burnBalance}
                                totalRx3Rewards={totalRx3Rewards}
                                tokenContract={tokenContract}
                                marketCap={marketCap}
                                supply={supply}
                                userHoldings={userHoldings}
                                lastRebaseDelta={lastRebaseDelta}
                                lastRebaseTimeStamp={lastRebaseTimeStamp}
                                referralContract={referralContract}
                                referralContractV2={referralContractV2}
                                mysteryBoxContract={mysteryBoxContract}
                                vestingVaultFactoryContract={vestingVaultFactoryContract}
                                nftContract={nftContract}
                                nftStakingContract={nftStakingContract}
                                isAdmin={isAdmin}
                                ercContractAbi={ercContractAbi}
                                ticker={ticker}
                                testnetMode={testnetMode}
                                chainInformation={chainInformation}
                                defaultProvider={defaultProvider}
                            />
                        </NavigationScroll>
                    </ThemeProvider>
                </StyledEngineProvider>
            </>
        </CacheBuster>
    );
};

export default App;
