export const nftStakingContractAbi = [
    {
        inputs: [
            {
                internalType: 'uint256[]',
                name: '_tokenIds',
                type: 'uint256[]'
            },
            {
                internalType: 'uint256',
                name: '_seconds',
                type: 'uint256'
            }
        ],
        name: 'claimRewards',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_rewardTokenAddress',
                type: 'address'
            },
            {
                internalType: 'address',
                name: '_erc721TokenAddress',
                type: 'address'
            }
        ],
        stateMutability: 'nonpayable',
        type: 'constructor'
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: '_stakeholder',
                type: 'address'
            },
            {
                indexed: true,
                internalType: 'uint256',
                name: '_claimAmount',
                type: 'uint256'
            }
        ],
        name: 'ClaimRewards',
        type: 'event'
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'previousOwner',
                type: 'address'
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'newOwner',
                type: 'address'
            }
        ],
        name: 'OwnershipTransferred',
        type: 'event'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '_seconds',
                type: 'uint256'
            }
        ],
        name: 'removeStakingTimeCommitment',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [],
        name: 'renounceOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '_rewardRate',
                type: 'uint256'
            }
        ],
        name: 'setDailyRewardRate',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_erc721Token',
                type: 'address'
            }
        ],
        name: 'setERC721TokenAddress',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '_amountPerTransation',
                type: 'uint256'
            }
        ],
        name: 'setMaxNFTsPerTransaction',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256[]',
                name: '_tokenIds',
                type: 'uint256[]'
            },
            {
                internalType: 'uint256',
                name: '_multiplierNumerator',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: '_multiplierDenominator',
                type: 'uint256'
            }
        ],
        name: 'setMultiplierOf',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'bool',
                name: '_state',
                type: 'bool'
            }
        ],
        name: 'setRewardsUnlocked',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_rewardToken',
                type: 'address'
            }
        ],
        name: 'setRewardToken',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '_seconds',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: '_multiplierNumerator',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: '_multiplierDenominator',
                type: 'uint256'
            }
        ],
        name: 'setStakingTimeCommitment',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256[]',
                name: '_tokenIds',
                type: 'uint256[]'
            },
            {
                internalType: 'uint256',
                name: '_seconds',
                type: 'uint256'
            }
        ],
        name: 'stakeNFT',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'uint256',
                name: '_tokenId',
                type: 'uint256'
            },
            {
                indexed: true,
                internalType: 'address',
                name: '_owner',
                type: 'address'
            }
        ],
        name: 'StakeNFT',
        type: 'event'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'newOwner',
                type: 'address'
            }
        ],
        name: 'transferOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256[]',
                name: '_tokenIds',
                type: 'uint256[]'
            }
        ],
        name: 'unstakeNFT',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'uint256',
                name: '_tokenId',
                type: 'uint256'
            },
            {
                indexed: true,
                internalType: 'address',
                name: '_owner',
                type: 'address'
            }
        ],
        name: 'UnstakeNFT',
        type: 'event'
    },
    {
        inputs: [],
        name: 'withdrawETHAssets',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [],
        name: 'withdrawRewardTokenAssets',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        stateMutability: 'payable',
        type: 'receive'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_owner',
                type: 'address'
            }
        ],
        name: 'claimableRewardsOfStakedTokens',
        outputs: [
            {
                internalType: 'uint256[]',
                name: '',
                type: 'uint256[]'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'dailyRewardRate',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'erc721Token',
        outputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'maxNFTsPerTransaction',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        name: 'nftIdRewardMultiplier',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'owner',
        outputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_owner',
                type: 'address'
            }
        ],
        name: 'pendingRewardsOfStakedTokens',
        outputs: [
            {
                internalType: 'uint256[]',
                name: '',
                type: 'uint256[]'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '_tokenId',
                type: 'uint256'
            }
        ],
        name: 'rewardOf',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'rewardsUnlocked',
        outputs: [
            {
                internalType: 'bool',
                name: '',
                type: 'bool'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'rewardToken',
        outputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_stakeholder',
                type: 'address'
            }
        ],
        name: 'stakedTokensOf',
        outputs: [
            {
                internalType: 'uint256[]',
                name: '',
                type: 'uint256[]'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_stakeholder',
                type: 'address'
            }
        ],
        name: 'stakeholderInfo',
        outputs: [
            {
                components: [
                    {
                        internalType: 'uint256',
                        name: 'tokenId',
                        type: 'uint256'
                    },
                    {
                        internalType: 'address',
                        name: 'stakeholder',
                        type: 'address'
                    },
                    {
                        internalType: 'uint256',
                        name: 'lastRewardTimestamp',
                        type: 'uint256'
                    },
                    {
                        internalType: 'uint256',
                        name: 'endStakeTimestamp',
                        type: 'uint256'
                    },
                    {
                        internalType: 'uint256',
                        name: 'currentTokenRewardMultiplier',
                        type: 'uint256'
                    },
                    {
                        components: [
                            {
                                internalType: 'uint256',
                                name: 'time',
                                type: 'uint256'
                            },
                            {
                                internalType: 'uint256',
                                name: 'multiplierNumerator',
                                type: 'uint256'
                            }
                        ],
                        internalType: 'struct PhenixNFTStaking.Commitment',
                        name: 'currentCommitment',
                        type: 'tuple'
                    },
                    {
                        internalType: 'uint256',
                        name: 'dailyRewardRate',
                        type: 'uint256'
                    }
                ],
                internalType: 'struct PhenixNFTStaking.Stake[]',
                name: '',
                type: 'tuple[]'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address'
            },
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        name: 'stakeholderTokens',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address'
            }
        ],
        name: 'stakeholderTotalRewards',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        name: 'stakeMapping',
        outputs: [
            {
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256'
            },
            {
                internalType: 'address',
                name: 'stakeholder',
                type: 'address'
            },
            {
                internalType: 'uint256',
                name: 'lastRewardTimestamp',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: 'endStakeTimestamp',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: 'currentTokenRewardMultiplier',
                type: 'uint256'
            },
            {
                components: [
                    {
                        internalType: 'uint256',
                        name: 'time',
                        type: 'uint256'
                    },
                    {
                        internalType: 'uint256',
                        name: 'multiplierNumerator',
                        type: 'uint256'
                    }
                ],
                internalType: 'struct PhenixNFTStaking.Commitment',
                name: 'currentCommitment',
                type: 'tuple'
            },
            {
                internalType: 'uint256',
                name: 'dailyRewardRate',
                type: 'uint256'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        name: 'stakingTimeCommitmentMapping',
        outputs: [
            {
                internalType: 'uint256',
                name: 'time',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: 'multiplierNumerator',
                type: 'uint256'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_owner',
                type: 'address'
            }
        ],
        name: 'totalClaimableRewardsOf',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_owner',
                type: 'address'
            }
        ],
        name: 'totalPendingRewardOf',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'totalStaked',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    }
];
