export const mysteryBoxContractAbi = [
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '_boxRequestIndex',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: '_rewardETHAmount',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: '_rewardERC20Amount',
                type: 'uint256'
            },
            {
                internalType: 'uint256[]',
                name: '_rewardERC721Tokens',
                type: 'uint256[]'
            }
        ],
        name: 'distributeBox',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_admin',
                type: 'address'
            },
            {
                internalType: 'address',
                name: '_tokenERC20Address',
                type: 'address'
            },
            {
                internalType: 'address',
                name: '_tokenERC721Address',
                type: 'address'
            }
        ],
        stateMutability: 'nonpayable',
        type: 'constructor'
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'previousOwner',
                type: 'address'
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'newOwner',
                type: 'address'
            }
        ],
        name: 'OwnershipTransferred',
        type: 'event'
    },
    {
        inputs: [],
        name: 'renounceOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'uint256',
                name: 'boxIndex',
                type: 'uint256'
            },
            {
                indexed: true,
                internalType: 'uint256',
                name: 'boxQuantity',
                type: 'uint256'
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'recipient',
                type: 'address'
            }
        ],
        name: 'RequestBox',
        type: 'event'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_recipient',
                type: 'address'
            },
            {
                internalType: 'uint256',
                name: '_boxIndex',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: '_boxQuantity',
                type: 'uint256'
            }
        ],
        name: 'requestBoxAdmin',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '_boxIndex',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: '_boxQuantity',
                type: 'uint256'
            }
        ],
        name: 'requestBoxWithETH',
        outputs: [],
        stateMutability: 'payable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '_boxIndex',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: '_boxQuantity',
                type: 'uint256'
            }
        ],
        name: 'requestBoxWithToken',
        outputs: [],
        stateMutability: 'payable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '_boxIndex',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: '_boxCostETH',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: '_boxCostERC20',
                type: 'uint256'
            },
            {
                internalType: 'bool',
                name: '_useETHPrice',
                type: 'bool'
            },
            {
                internalType: 'address',
                name: '_rewardERC20Address',
                type: 'address'
            },
            {
                internalType: 'address',
                name: '_rewardERC721Address',
                type: 'address'
            },
            {
                internalType: 'uint256',
                name: '_minimumERC20Holdings',
                type: 'uint256'
            },
            {
                internalType: 'bool',
                name: '_boxEnabled',
                type: 'bool'
            }
        ],
        name: 'setBox',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_address',
                type: 'address'
            },
            {
                internalType: 'bool',
                name: '_status',
                type: 'bool'
            }
        ],
        name: 'setBoxFeeExemptUser',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '_boxRequestFee',
                type: 'uint256'
            }
        ],
        name: 'setBoxRequestFee',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'bool',
                name: '_status',
                type: 'bool'
            }
        ],
        name: 'setEnabledStatus',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_tokenAddress',
                type: 'address'
            }
        ],
        name: 'setERC20Address',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_tokenAddress',
                type: 'address'
            }
        ],
        name: 'setERC721Address',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '_numerator',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: '_denominator',
                type: 'uint256'
            }
        ],
        name: 'setERC721Discount',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '_numerator',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: '_denominator',
                type: 'uint256'
            }
        ],
        name: 'setERC721DiscountMaximum',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '_maxBoxQuantity',
                type: 'uint256'
            }
        ],
        name: 'setMaxBoxQuantity',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_address',
                type: 'address'
            }
        ],
        name: 'setRouterAddress',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_user',
                type: 'address'
            },
            {
                internalType: 'bool',
                name: '_status',
                type: 'bool'
            }
        ],
        name: 'setUserAdmin',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'newOwner',
                type: 'address'
            }
        ],
        name: 'transferOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '_boxRequestIndex',
                type: 'uint256'
            },
            {
                internalType: 'enum PhenixMysteryBox.BoxRequestStatus',
                name: '_status',
                type: 'uint8'
            }
        ],
        name: 'updateBoxRequestStatus',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_tokenAddress',
                type: 'address'
            },
            {
                internalType: 'uint256',
                name: '_amount',
                type: 'uint256'
            }
        ],
        name: 'withdrawERC20Token',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_erc721Address',
                type: 'address'
            },
            {
                internalType: 'uint256[]',
                name: '_tokenIds',
                type: 'uint256[]'
            }
        ],
        name: 'withdrawERC721Tokens',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '_amount',
                type: 'uint256'
            }
        ],
        name: 'withdrawETH',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        stateMutability: 'payable',
        type: 'receive'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address'
            }
        ],
        name: 'adminMapping',
        outputs: [
            {
                internalType: 'bool',
                name: '',
                type: 'bool'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '_boxValue',
                type: 'uint256'
            },
            {
                internalType: 'address',
                name: '_user',
                type: 'address'
            }
        ],
        name: 'boxCostOf',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        name: 'boxes',
        outputs: [
            {
                internalType: 'uint256',
                name: 'boxIndex',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: 'boxCostETH',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: 'boxCostERC20',
                type: 'uint256'
            },
            {
                internalType: 'bool',
                name: 'useETHPrice',
                type: 'bool'
            },
            {
                internalType: 'address',
                name: 'rewardERC20Address',
                type: 'address'
            },
            {
                internalType: 'address',
                name: 'rewardERC721Address',
                type: 'address'
            },
            {
                internalType: 'uint256',
                name: 'minimumERC20Holdings',
                type: 'uint256'
            },
            {
                internalType: 'bool',
                name: 'boxEnabled',
                type: 'bool'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address'
            }
        ],
        name: 'boxFeeExempt',
        outputs: [
            {
                internalType: 'bool',
                name: '',
                type: 'bool'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'boxRequestFee',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        name: 'boxRequests',
        outputs: [
            {
                internalType: 'uint256',
                name: 'boxRequestIndex',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: 'boxIndex',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: 'boxQuantity',
                type: 'uint256'
            },
            {
                internalType: 'enum PhenixMysteryBox.BoxRequestStatus',
                name: 'boxStatus',
                type: 'uint8'
            },
            {
                internalType: 'address',
                name: 'recipient',
                type: 'address'
            },
            {
                internalType: 'uint256',
                name: 'rewardERC20Amount',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: 'rewardETHAmount',
                type: 'uint256'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'getBalance',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '_count',
                type: 'uint256'
            }
        ],
        name: 'getBoxes',
        outputs: [
            {
                components: [
                    {
                        internalType: 'uint256',
                        name: 'boxIndex',
                        type: 'uint256'
                    },
                    {
                        internalType: 'uint256',
                        name: 'boxCostETH',
                        type: 'uint256'
                    },
                    {
                        internalType: 'uint256',
                        name: 'boxCostERC20',
                        type: 'uint256'
                    },
                    {
                        internalType: 'bool',
                        name: 'useETHPrice',
                        type: 'bool'
                    },
                    {
                        internalType: 'address',
                        name: 'rewardERC20Address',
                        type: 'address'
                    },
                    {
                        internalType: 'address',
                        name: 'rewardERC721Address',
                        type: 'address'
                    },
                    {
                        internalType: 'uint256',
                        name: 'minimumERC20Holdings',
                        type: 'uint256'
                    },
                    {
                        internalType: 'bool',
                        name: 'boxEnabled',
                        type: 'bool'
                    }
                ],
                internalType: 'struct PhenixMysteryBox.Box[]',
                name: '',
                type: 'tuple[]'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '_count',
                type: 'uint256'
            }
        ],
        name: 'getBoxPrices',
        outputs: [
            {
                components: [
                    {
                        internalType: 'uint256',
                        name: 'ethPrice',
                        type: 'uint256'
                    },
                    {
                        internalType: 'uint256',
                        name: 'erc20Price',
                        type: 'uint256'
                    }
                ],
                internalType: 'struct PhenixMysteryBox.BoxCost[]',
                name: '',
                type: 'tuple[]'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '_count',
                type: 'uint256'
            }
        ],
        name: 'getBoxRequests',
        outputs: [
            {
                components: [
                    {
                        internalType: 'uint256',
                        name: 'boxRequestIndex',
                        type: 'uint256'
                    },
                    {
                        internalType: 'uint256',
                        name: 'boxIndex',
                        type: 'uint256'
                    },
                    {
                        internalType: 'uint256',
                        name: 'boxQuantity',
                        type: 'uint256'
                    },
                    {
                        internalType: 'enum PhenixMysteryBox.BoxRequestStatus',
                        name: 'boxStatus',
                        type: 'uint8'
                    },
                    {
                        internalType: 'address',
                        name: 'recipient',
                        type: 'address'
                    },
                    {
                        internalType: 'uint256',
                        name: 'rewardERC20Amount',
                        type: 'uint256'
                    },
                    {
                        internalType: 'uint256',
                        name: 'rewardETHAmount',
                        type: 'uint256'
                    },
                    {
                        internalType: 'uint256[]',
                        name: 'rewardERC721Tokens',
                        type: 'uint256[]'
                    }
                ],
                internalType: 'struct PhenixMysteryBox.BoxRequest[]',
                name: '',
                type: 'tuple[]'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'getBoxRequestsCount',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '_ethAmount',
                type: 'uint256'
            }
        ],
        name: 'getFeeTokenAmount',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'isEnabled',
        outputs: [
            {
                internalType: 'bool',
                name: '',
                type: 'bool'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'maxBoxQuantity',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'owner',
        outputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'routerAddress',
        outputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'tokenERC20Address',
        outputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'tokenERC721Address',
        outputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'tokenERC721Discount',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'tokenERC721DiscountMaximum',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    }
];
