// Import the functions you need from the SDKs you need
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: 'AIzaSyCiwUNUTeZpNuGKF7fjIkf_RIgnWqFnZB8',
    authDomain: 'phenix-app-881db.firebaseapp.com',
    projectId: 'phenix-app-881db',
    storageBucket: 'phenix-app-881db.appspot.com',
    messagingSenderId: '754212629103',
    appId: '1:754212629103:web:39c73d033367d59d01fb9a',
    measurementId: 'G-Q3QSWKB1JD'
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
const functions = firebase.functions();

export default db;
