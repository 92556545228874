// assets
import { IconTicket, IconDroplet, IconExternalLink } from '@tabler/icons';
// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const icoAndDrops = {
    id: 'icoAndDrops',
    title: 'ICOs / Drops',
    type: 'group',
    acceptedChains: [137, 25],
    children: [
        {
            id: 'token-drops',
            title: 'Token Presales',
            type: 'item',
            url: '/presales',
            icon: IconTicket,
            breadcrumbs: false,
            acceptedChains: [137, 25]
        },
        {
            id: 'nft-drops',
            title: 'NFT Drops',
            type: 'item',
            url: 'https://dex.phenix.finance/drops',
            icon: IconExternalLink,
            external: true,
            breadcrumbs: false,
            acceptedChains: [137, 25]
        }
    ]
};

export default icoAndDrops;
