// assets
import { IconWorld, IconBook, IconBrandDiscord, IconBrandTelegram } from '@tabler/icons';
// ==============================|| RESOURCES MENU ITEMS ||============================== //

const resources = {
    id: 'resources',
    title: 'Resources',
    type: 'group',
    acceptedChains: [25, 338, 137],
    children: [
        {
            id: 'website',
            title: 'Website',
            type: 'item',
            url: 'https://phenix.finance/',
            icon: IconWorld,
            breadcrumbs: false,
            external: true,
            target: true,
            acceptedChains: [25, 338, 137]
        },
        {
            id: 'whitepaper',
            title: 'Whitepaper',
            type: 'item',
            url: 'https://docs.phenix.finance/',
            icon: IconBook,
            breadcrumbs: false,
            external: true,
            target: true,
            acceptedChains: [25, 338, 137]
        },
        {
            id: 'discord',
            title: 'Discord',
            type: 'item',
            url: 'https://discord.com/invite/4tbsFf5e5v',
            icon: IconBrandDiscord,
            breadcrumbs: false,
            external: true,
            target: true,
            acceptedChains: [25, 338, 137]
        },
        {
            id: 'telegram',
            title: 'Telegram',
            type: 'item',
            url: 'https://t.me/phenixfinance',
            icon: IconBrandTelegram,
            breadcrumbs: false,
            external: true,
            target: true,
            acceptedChains: [25, 338, 137]
        }
    ]
};

export default resources;
