// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = ({ chainInformation }) => {
    const navItems = menuItem.items.map((item) => {
        switch (item.type) {
            case 'group':
                return chainInformation !== null &&
                    (item.acceptedChains.includes(chainInformation.chainId) || item.acceptedChains.includes(-1)) ? (
                    <NavGroup key={item.id} item={item} chainInformation={chainInformation} />
                ) : (
                    ''
                );
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;
