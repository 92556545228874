// assets
import { IconColorSwatch, IconNewSection, IconSend, IconLayoutGrid, IconLicense, IconFlame, IconSoccerField } from '@tabler/icons';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const nfts = {
    id: 'nfts',
    title: 'NFTs',
    type: 'group',
    acceptedChains: [25, 137],
    children: [
        {
            id: 'phnx-nfts',
            title: 'Phenix NFTs',
            type: 'collapse',
            icon: IconColorSwatch,
            breadcrumbs: false,
            acceptedChains: [25, 137],
            children: [
                {
                    id: 'fpc-nfts',
                    title: 'My FPC NFTs',
                    type: 'item',
                    url: '/nft',
                    icon: IconFlame,
                    breadcrumbs: false,
                    acceptedChains: [25, 137]
                },
                {
                    id: 'pwct-nfts',
                    title: 'My PWCT NFTs',
                    type: 'item',
                    url: '/nft-pwct',
                    icon: IconSoccerField,
                    breadcrumbs: false,
                    acceptedChains: [25]
                }
            ]
        },
        {
            id: 'stake-nfts',
            title: 'Stake NFTs',
            type: 'item',
            url: '/stake-nft',
            icon: IconSend,
            breadcrumbs: false,
            acceptedChains: [25, 137]
        },
        /*{
            id: 'vote-nfts',
            title: 'Voting',
            type: 'item',
            url: '/voting',
            icon: IconLicense,
            breadcrumbs: false
        },*/
        {
            id: 'cronos-nft-collection',
            title: 'View Collection',
            type: 'item',
            url: 'https://app.ebisusbay.com/collection/flaming-phenix-club',
            icon: IconLayoutGrid,
            breadcrumbs: false,
            external: true,
            target: true,
            acceptedChains: [25]
        },
        {
            id: 'polygon-nft-collection',
            title: 'View Collection',
            type: 'item',
            url: 'https://opensea.io/collection/poly-fpc',
            icon: IconLayoutGrid,
            breadcrumbs: false,
            external: true,
            target: true,
            acceptedChains: [137]
        }
    ]
};

export default nfts;
