// assets
import { IconEyeglass, IconLock } from '@tabler/icons';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const other = {
    id: 'other',
    title: 'Other',
    type: 'group',
    acceptedChains: [25, 338],
    children: [
        {
            id: 'transparency',
            title: 'Transparency',
            type: 'collapse',
            icon: IconEyeglass,
            breadcrumbs: false,
            acceptedChains: [25, 338],
            children: [
                {
                    id: 'transparency-pfr',
                    title: 'PFR Transactions',
                    type: 'item',
                    url: '/vault/transparency/0xDa88149E1C931b31Aa1E382A5332E2Ee41C90313',
                    icon: IconLock,
                    acceptedChains: [25, 338]
                }
            ]
        }
    ]
};

export default other;
