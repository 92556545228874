// assets
import { IconKey, IconBox, IconLock, IconLockAccess } from '@tabler/icons';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const phenixVault = {
    id: 'phenix-vault',
    title: 'Phenix Vault',
    type: 'group',
    acceptedChains: [25],
    children: [
        {
            id: 'multi-sig-group',
            title: 'Phenix Multi-sig Wallets',
            type: 'collapse',
            icon: IconLock,
            breadcrumbs: false,
            acceptedChains: [25],
            children: [
                {
                    id: 'my-multi-sig-wallets',
                    title: 'My Wallets',
                    type: 'item',
                    url: '/vault/multi-sig',
                    icon: IconKey,
                    breadcrumbs: false,
                    acceptedChains: [25]
                },
                {
                    id: 'create-multi-sig-wallet',
                    title: 'Create Multi-Sig Wallet',
                    type: 'item',
                    url: '/vault/multi-sig/create',
                    icon: IconBox,
                    breadcrumbs: false,
                    acceptedChains: [25, 338]
                }
            ]
        },
        {
            id: 'phenix-personal-vault-group',
            title: 'Phenix Asset Vaults',
            type: 'collapse',
            icon: IconLockAccess,
            breadcrumbs: false,
            acceptedChains: [25],
            children: [
                {
                    id: 'my-asset-vaults',
                    title: 'My Asset Vaults',
                    type: 'item',
                    icon: IconLockAccess,
                    url: '/vault/vesting',
                    acceptedChains: [25]
                },
                {
                    id: 'create-asset-vaults',
                    title: 'Create Asset Vault',
                    type: 'item',
                    icon: IconBox,
                    url: '/vault/create-vesting',
                    acceptedChains: [25]
                }
            ]
        }
    ]
};

export default phenixVault;
