// assets
import {
    IconTrophy,
    IconDeviceDesktopAnalytics,
    IconBook,
    IconShieldLock,
    IconEyeglass,
    IconLockAccess,
    IconCalendarTime,
    IconCoin,
    IconKey
} from '@tabler/icons';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const comingSoon = {
    id: 'coming-soon',
    title: 'Coming Soon',
    type: 'group',
    acceptedChains: [25, 338],
    children: [
        {
            id: 'statistics',
            title: 'Statistics',
            type: 'item',
            url: '/statistics',
            icon: IconDeviceDesktopAnalytics,
            breadcrumbs: false,
            acceptedChains: [25, 338]
        },
        {
            id: 'phenix-vault',
            title: 'Phenix Vault',
            type: 'collapse',
            url: '/vault',
            icon: IconShieldLock,
            breadcrumbs: false,
            children: [
                {
                    id: 'create-vesting-vault',
                    title: 'Token/LP Vesting Vault',
                    type: 'item',
                    icon: IconLockAccess,
                    url: '/vault/vesting',
                    acceptedChains: [25, 338]
                },
                {
                    id: 'create-token',
                    title: 'Create Custom CRC20 Token',
                    type: 'item',
                    icon: IconCoin,
                    url: '/vault/create-token',
                    acceptedChains: [25, 338]
                }
            ],
            acceptedChains: [25, 338]
        },
        {
            id: 'learn',
            title: 'Learn',
            type: 'item',
            url: '/learn',
            icon: IconBook,
            breadcrumbs: false,
            acceptedChains: [25, 338]
        }
    ]
};

export default comingSoon;
