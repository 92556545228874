// assets
import { IconDashboard, IconLink, IconDiamond, IconFlame, IconCrown, IconCurrencyEthereum } from '@tabler/icons';
// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const swap = {
    id: 'swap',
    title: 'Swap',
    type: 'group',
    acceptedChains: [25, 137],
    children: [
        {
            id: 'buy',
            title: 'Buy PHNX',
            type: 'item',
            url: '/swap',
            icon: IconFlame,
            breadcrumbs: false,
            acceptedChains: [25, 137]
        },
        {
            id: 'bridge-swapp',
            title: 'Cross-Chain Swap',
            type: 'item',
            url: 'https://app.xy.finance/?fromTokenAddress=0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE&sourceChainId=56&toTokenAddress=0x91381CED862941aF90995bC48336643a37CDA580&targetChainId=25&amount&slippage=20',
            icon: IconCurrencyEthereum,
            breadcrumbs: false,
            external: true,
            target: true,
            acceptedChains: [25, 137]
        },
        {
            id: 'referral-program',
            title: 'Referral Program',
            type: 'collapse',
            url: '/referrals',
            icon: IconLink,
            breadcrumbs: false,
            acceptedChains: [25, 137],
            children: [
                {
                    id: 'referral-program-main',
                    title: 'Join/View Referral Program',
                    type: 'item',
                    icon: IconLink,
                    url: '/referrals',
                    acceptedChains: [25, 137]
                },
                {
                    id: 'referral-program-leaderboard',
                    title: 'PRP Leaderboard',
                    type: 'item',
                    icon: IconCrown,
                    url: '/referrals/leaderboard',
                    acceptedChains: [25, 137]
                }
            ]
        }
    ]
};

export default swap;
