const polygonContractAddresses = {
    nftContractAddress: '0x752892900c197B9C5b10B93e6F19B0365c296F18',
    referralContractAddress: '0xEDaAbC4cB859cFe8d1356e21c526584cD5B0a447',
    referralContractV2Address: '0x6426B7318049C4FDbA2A6b35EeF8273b4BB92d11',
    tokenContractAddress: '0x6C997a37f5a1Dca62b58EEB01041f056942966B3',
    presaleContractAddress: '0x300a076F1e3eC37Ab5B2A38ACF61854E9dFd2863',
    routerContractAddress: '0xE7E3B9907570FA2F71D5f5b2bDC33e80eD5Db7A6',
    mysteryBoxContractAddress: '0x8AbCab16372160679D6A155d96D9986454136a0a',
    vestingVaultFactory: '0xd0603b8FF8be4609F25DDbf0c0e3E0D15CB2C5df'
};

export default polygonContractAddresses;
