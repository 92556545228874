import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Web3 from 'web3';

// material-ui
import { Dialog, DialogTitle, List, ListItem, ListItemAvatar, ListItemText, Avatar, Snackbar, Alert } from '@mui/material';

// config
import Config from 'utils/walletConnectConfig';
import { ethers } from 'ethers';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { DeFiWeb3Connector } from 'deficonnect';

// contract addresses
import polygonContractAddresses from 'utils/contractAddresses/polygonContractData';
import cronosContractAddresses from 'utils/contractAddresses/cronosContractData';

const ConnectWalletModule = ({ isShowing, setIsShowing, wallet, setWallet, setAddress, chainInformation, setChainInformation }) => {
    const navigate = useNavigate();
    const [currentConnectMethod, setCurrentConnectMethod] = useState('mm');
    const [IOSWalletConnectURI, setIOSWalletConnectURI] = useState(null);
    const [open, setOpen] = useState(true);

    // -----------------------------------------------------------------
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState(false);
    const [snackbarType, setSnackbarType] = useState('success');
    // -----------------------------------------------------------------

    const handleClose = () => {
        setIsShowing(false);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleChainSwitch = (chainIndex) => {
        switch (chainIndex) {
            case 25:
                setChainInformation({
                    assetPlatform: 'cronos',
                    chainName: 'cronos',
                    coinName: 'Cronos',
                    coinSymbol: 'CRO',
                    coinTxnScanner: 'https://cronoscan.com/tx/',
                    chainId: 25,
                    chainRpc: 'https://evm-cronos.crypto.org/',
                    chainLogo: '/resources/images/cro-logo.svg',
                    contractAddresses: cronosContractAddresses,
                    nftImageEndpoint: 'https://phenix.finance/nfts/cronos/',
                    raritySniperEndpoint: 'https://raritysniper.com/flaming-phenix-club/',
                    ebisusBayMarketplaceEndpoint: 'https://app.ebisusbay.com/collection/flaming-phenix-club/',
                    openSeaMarketplaceEndpoint: null,
                    nftBannerImage: 'resources/images/bg_op.png',
                    coinGeckoIds: {
                        eth: 'crypto-com-chain',
                        phnx: 'phenix-finance-2'
                    },
                    buyLink: 'https://dex.phenix.finance/swap?out=PHNX'
                });
                return true;
            case 137:
                setChainInformation({
                    assetPlatform: 'polygon-pos',
                    chainName: 'polygon',
                    coinName: 'Polygon',
                    coinSymbol: 'MATIC',
                    coinTxnScanner: 'https://polygonscan.com/tx/',
                    chainId: 137,
                    chainRpc: 'https://polygon-rpc.com/',
                    chainLogo: '/resources/images/polygon-matic-logo.svg',
                    contractAddresses: polygonContractAddresses,
                    nftImageEndpoint: 'https://phenix.finance/nfts/polygon/',
                    openSeaMarketplaceEndpoint: 'https://opensea.io/assets/matic/0x752892900c197b9c5b10b93e6f19b0365c296f18/',
                    raritySniperEndpoint: null,
                    ebisusBayMarketplaceEndpoint: null,
                    nftBannerImage: 'resources/images/polygon-nft-background.jpg',
                    coinGeckoIds: {
                        eth: 'matic-network',
                        phnx: ''
                    },
                    buyLink: 'https://dex.phenix.finance/swap?out=PHNX'
                });
                return true;
            default:
                setSnackbarMessage(`Invalid Chain ID ${chainIndex}! Please use Cronos or Polygon Chain.`);
                setSnackbarType('error');
                setSnackbarOpen(true);
                navigate('/');

                const _chainId = 25;
                try {
                    window.ethereum.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{ chainId: ethers.utils.hexlify(_chainId) }]
                    });
                } catch (err) {
                    // This error code indicates that the chain has not been added to MetaMask
                    if (err.code === 4902) {
                        window.ethereum.request({
                            method: 'wallet_addEthereumChain',
                            params: [
                                {
                                    chainName: 'Cronos Mainnet',
                                    chainId: ethers.utils.hexlify(_chainId),
                                    nativeCurrency: { name: 'CRO', decimals: 18, symbol: 'CRO' },
                                    rpcUrls: ['https://evm.cronos.org/']
                                }
                            ]
                        });
                    }
                }
                return false;
        }
    };

    const handleEthereumUpdate = () => {
        if (window.ethereum) {
            if (wallet == null) {
                handleEthereumConnect();
            }

            window.ethereum.removeListener('accountsChanged', () => {});
            window.ethereum.on('accountsChanged', (accounts) => {
                shouldChangeAccount();
            });

            window.ethereum.removeListener('chainChanged', () => {});
            window.ethereum.on('chainChanged', (chainId) => {
                shouldChangeChain();
            });
        }
    };

    const shouldChangeAccount = () => {
        // console.log(window.localStorage.getItem('method'));
        if (window.localStorage.getItem('method') == 'mm') {
            handleEthereumConnect();
        }
    };

    const shouldChangeChain = () => {
        // console.log(window.localStorage.getItem('method'));
        if (window.localStorage.getItem('method') == 'mm') {
            // window.location.reload();
        }
    };

    const handleEthereumConnect = async () => {
        if (window.ethereum) {
            const chainId = await window.ethereum.request({ method: 'eth_chainId' });
            if (handleChainSwitch(parseInt(chainId))) {
                const metamask = new ethers.providers.Web3Provider(window.ethereum);
                await window.ethereum.request({
                    method: 'eth_requestAccounts'
                });

                const signer = metamask.getSigner();

                setWallet(signer);
                window.localStorage.setItem('method', 'mm');
                signer.getAddress().then((_address) => {
                    setAddress(_address);
                });

                handleClose();
            }
        }
    };

    const connectWalletConnect = async (option) => {
        const rpcInfo = Config.mode == 'regular' ? Config.rpcNetwork_mainnet : Config.rpcNetwork;
        const web3 = new Web3(rpcInfo.rpcUrl);
        try {
            if (wallet == null) {
                setIsShowing(false);
                // localStorage.clear();
                let provider;

                if (option == 'via_defi') {
                    provider = new WalletConnectProvider({
                        rpc: {
                            [rpcInfo.chainId]: rpcInfo.rpcUrl,
                            [137]: 'https://polygon-rpc.com/'
                        },
                        chainId: rpcInfo.chainId,
                        supportedChainIds: [rpcInfo.chainId, 137],
                        qrcodeModalOptions: {
                            mobileLinks: ['crypto.com', 'metamask', 'trust wallet']
                        }
                        // qrcode: false
                    });
                } else {
                    provider = new WalletConnectProvider({
                        rpc: {
                            [rpcInfo.chainId]: rpcInfo.rpcUrl,
                            [137]: 'https://polygon-rpc.com/'
                        },
                        chainId: rpcInfo.chainId,
                        supportedChainIds: [rpcInfo.chainId, 137],
                        qrcodeModalOptions: {
                            mobileLinks: ['crypto.com', 'metamask', 'trust wallet']
                        }
                    });
                }

                let ethersProvider;

                provider.enable().then(
                    () => {
                        ethersProvider = new ethers.providers.Web3Provider(provider);
                        handleChainSwitch(ethersProvider.provider.chainId);
                        setAddress(ethersProvider.provider.accounts[0]);
                        window.localStorage.setItem('method', 'wc');
                        setWallet(ethersProvider.getSigner());
                    },
                    (reason) => {
                        alert(reason);
                    }
                );
            } else {
                setWallet(null);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const connectMetamask = async () => {
        handleEthereumConnect();
    };

    const connectDefiWallet = async () => {
        const rpcInfo = Config.mode == 'regular' ? Config.rpcNetwork_mainnet : Config.rpcNetwork;
        const web3 = new Web3(rpcInfo.rpcUrl);
        try {
            let provider;
            let web3Provider;

            setIsShowing(false);
            const connector = new DeFiWeb3Connector({
                supportedChainIds: [rpcInfo.chainId],
                rpc: {
                    [rpcInfo.chainId]: rpcInfo.rpcUrl
                },
                pollingInterval: 15000
            });

            connector.activate().then(() => {
                connector.getProvider().then((_provider) => {
                    provider = _provider;
                    web3Provider = new ethers.providers.Web3Provider(provider);

                    handleChainSwitch(parseInt(provider.chainId, 16));
                    setWallet(web3Provider.getSigner());
                    window.localStorage.setItem('method', 'defi');
                    setAddress(web3Provider.provider.accounts[0]);
                });
            });

            // Subscribe to events that reload the app
            // connector.on('session_update', false);
            // connector.on('Web3ReactDeactivate', false);
            // connector.on('Web3ReactUpdate', false);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        handleEthereumUpdate();
    }, []);

    return (
        <>
            <Snackbar open={snackbarOpen} autoHideDuration={10000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarType} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <Dialog fullWidth maxWidth="sm" onClose={handleClose} open={isShowing}>
                <DialogTitle>Connect Wallet</DialogTitle>
                <List sx={{ pt: 0 }}>
                    <ListItem
                        button
                        onClick={() => {
                            connectDefiWallet();
                        }}
                        key={`defi-wallet-connection`}
                    >
                        <ListItemAvatar>
                            <Avatar>
                                <img src="/resources/images/cro-logo.svg" style={{ width: '24px' }} alt="Cronos Defi Wallet" />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={'DeFi Wallet'} />
                    </ListItem>
                    <ListItem
                        button
                        onClick={() => {
                            connectMetamask();
                        }}
                        key={'metamask-wallet-connection'}
                    >
                        <ListItemAvatar>
                            <Avatar>
                                <img src="/resources/images/mm-logo.svg" style={{ width: '24px' }} alt="MM" />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={'Metamask'} />
                    </ListItem>
                    <ListItem
                        button
                        onClick={() => {
                            connectMetamask();
                        }}
                        key={'trust-wallet-connection'}
                    >
                        <ListItemAvatar>
                            <Avatar>
                                <img src="/resources/images/TWT.svg" style={{ width: '24px' }} alt="TWT" />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={'Trust Wallet'} />
                    </ListItem>
                    <ListItem
                        button
                        onClick={() => {
                            connectWalletConnect('via_wc');
                        }}
                        key={2}
                    >
                        <ListItemAvatar>
                            <Avatar>
                                <img src="/resources/images/wc-logo.svg" style={{ width: '24px' }} alt="Cronos Defi Wallet" />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={'Wallet Connect'} />
                    </ListItem>
                </List>
            </Dialog>
        </>
    );
};

export default ConnectWalletModule;
