// assets
import { IconBox, IconTrophy, IconDice, IconHistory } from '@tabler/icons';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const PhenixGames = {
    id: 'phenix-games',
    title: 'Phenix Games',
    type: 'group',
    acceptedChains: [25, 137],
    children: [
        {
            id: 'phenix-lotto-group',
            title: 'Phenix Lotto Pool',
            type: 'collapse',
            icon: IconTrophy,
            acceptedChains: [25, 338],
            children: [
                {
                    id: 'phenix-lotto',
                    title: 'Enter Lotto Pool',
                    type: 'item',
                    url: '/phenix-lotto',
                    icon: IconDice,
                    breadcrumbs: false,
                    acceptedChains: [25, 338]
                },
                {
                    id: 'phenix-lotto-results',
                    title: 'View Past Results',
                    type: 'item',
                    url: '/plp-results',
                    icon: IconHistory,
                    breadcrumbs: false,
                    acceptedChains: [25, 338]
                }
            ]
        }
    ]
};

export default PhenixGames;
