const cronosContractAddresses = {
    nftContractAddress: '0xCf185Cb08c62Ff969D258FBA18F92dabb278A8A9',
    nftStakingContractAddress: '0x7263c41dff9F11de66b159c2474D0B6D8bf668f3',
    referralContractAddress: '0xA29e0Ad37849e581eBD8Ed13df443AE94eb938E6',
    referralContractV2Address: '0xF58Cd1DDE050CBe8e77BC25C9801e93aD433108f',
    tokenContractAddress: '0x91381CED862941aF90995bC48336643a37CDA580',
    mysteryBoxContractAddress: '0x2e7E287B53A880DdBCE12E0cBC40779c476CAB1e',
    routerContractAddress: '0x62bC82F8Ae8b6EBBEbc5aef9147F88dDEDE7CF28',
    vestingVaultFactory: '0xd1C1ca25c7D5eA3b2F6Af3ebf93c6c6e5f182469'
};

export default cronosContractAddresses;
