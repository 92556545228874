import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';

// routes
import config from 'config';

// project imports
import MainLayout from 'layout/MainLayout';
import MinimalLayout from 'layout/MinimalLayout';
import Loadable from 'ui-component/Loadable';
import { element } from 'prop-types';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard')));
const PhenixLotto = Loadable(lazy(() => import('views/phenix-lotto')));
const Statistics = Loadable(lazy(() => import('views/statistics')));
const PhenixVault = Loadable(lazy(() => import('views/phenix-vault')));
const Transparency = Loadable(lazy(() => import('views/transparency')));
const Rewards = Loadable(lazy(() => import('views/rewards')));
const Learn = Loadable(lazy(() => import('views/learn')));
const Referrals = Loadable(lazy(() => import('views/referrals')));
const Swap = Loadable(lazy(() => import('views/swap')));
const Admin = Loadable(lazy(() => import('views/admin')));
const MultiSig = Loadable(lazy(() => import('views/multi-sig')));
const MultiSigCreate = Loadable(lazy(() => import('views/multi-sig-create')));
const Vesting = Loadable(lazy(() => import('views/vesting')));
const CreateVesting = Loadable(lazy(() => import('views/vesting-create')));
const CreateToken = Loadable(lazy(() => import('views/create-token')));
const PRPLeaderboard = Loadable(lazy(() => import('views/prp_leaderboard')));
const MintNFTs = Loadable(lazy(() => import('views/mint-nfts')));
const StakeNFTs = Loadable(lazy(() => import('views/stake-nfts')));
const MyNFTs = Loadable(lazy(() => import('views/my-nfts')));
const PhenixLottoResults = Loadable(lazy(() => import('views/plp-results')));
const MysteryBox = Loadable(lazy(() => import('views/mystery-box')));
const Voting = Loadable(lazy(() => import('views/voting')));
const MultiSigTransparency = Loadable(lazy(() => import('views/multi-sig-transparency')));
const Holders = Loadable(lazy(() => import('views/holders')));
const MysteryBoxFair = Loadable(lazy(() => import('views/mystery-box-fair')));
const TokenPresales = Loadable(lazy(() => import('views/token-presales')));
const NFTDrops = Loadable(lazy(() => import('views/nft-drops')));
const DropManager = Loadable(lazy(() => import('views/drop-manager')));
const TokenPresaleManager = Loadable(lazy(() => import('views/token-presale-manager')));
const MyPWCTNFTs = Loadable(lazy(() => import('views/my-wc-nfts')));
const NFTMarketplace = Loadable(lazy(() => import('views/marketplace')));

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes(props) {
    return useRoutes(
        [
            {
                path: '/',
                element: <MainLayout {...props} />,
                children: [
                    {
                        path: '/',
                        element: <DashboardDefault {...props} />
                    },
                    {
                        path: '/phenix-lotto',
                        element: <PhenixLotto {...props} />
                    },
                    {
                        path: '/plp-results',
                        element: <PhenixLottoResults {...props} />
                    },
                    {
                        path: '/statistics',
                        element: <Statistics {...props} />
                    },
                    {
                        path: '/phenix-vault',
                        element: <PhenixVault {...props} />
                    },
                    {
                        path: '/transparency',
                        element: <Transparency {...props} />
                    },
                    {
                        path: '/marketplace',
                        element: <NFTMarketplace {...props} />
                    },
                    {
                        path: '/rewards',
                        element: <Rewards {...props} />
                    },
                    {
                        path: '/learn',
                        element: <Learn {...props} />
                    },
                    {
                        path: '/swap/:ref',
                        element: <Swap {...props} />
                    },
                    {
                        path: '/swap',
                        element: <Swap {...props} />
                    },
                    {
                        path: '/referrals',
                        element: <Referrals {...props} />
                    },
                    {
                        path: '/phenix-core-team',
                        element: <Admin {...props} />
                    },
                    {
                        path: '/mystery-boxes',
                        element: <MysteryBox {...props} />
                    },
                    {
                        path: '/voting',
                        element: <Voting {...props} />
                    },
                    {
                        path: '/holders',
                        element: <Holders {...props} />
                    },
                    {
                        path: '/mystery-box-fair',
                        element: <MysteryBoxFair {...props} />
                    },
                    {
                        path: '/presales',
                        element: <TokenPresales {...props} />
                    },
                    {
                        path: '/presales/:presaleName',
                        element: <TokenPresales {...props} />
                    },
                    {
                        path: '/presales/:id',
                        element: <TokenPresales {...props} />
                    },
                    {
                        path: '/drops',
                        element: <NFTDrops {...props} />
                    },
                    {
                        path: '/drops/:id',
                        element: <NFTDrops {...props} />
                    },
                    {
                        path: '/drops/admin',
                        element: <DropManager {...props} />
                    },
                    {
                        path: '/presales/admin',
                        element: <TokenPresaleManager {...props} />
                    },
                    {
                        path: '/vault',
                        children: [
                            {
                                path: '/multi-sig/:walletId',
                                element: <MultiSig {...props} />
                            },
                            {
                                path: '/multi-sig',
                                element: <MultiSig {...props} />
                            },
                            {
                                path: '/transparency/:walletId',
                                element: <MultiSigTransparency {...props} />
                            },
                            {
                                path: '/vesting',
                                element: <Vesting {...props} />
                            },
                            {
                                path: '/create-vesting',
                                element: <CreateVesting {...props} />
                            },
                            {
                                path: '/create-token',
                                element: <CreateToken {...props} />
                            }
                        ]
                    },
                    {
                        path: '/referrals/leaderboard',
                        element: <PRPLeaderboard {...props} />
                    },
                    {
                        path: '/vault/multi-sig/create',
                        element: <MultiSigCreate {...props} />
                    },
                    {
                        path: '/nft',
                        element: <MyNFTs {...props} />
                    },
                    {
                        path: '/nft-pwct',
                        element: <MyPWCTNFTs {...props} />
                    },
                    {
                        path: '/stake-nft',
                        element: <StakeNFTs {...props} />
                    }
                ]
            }
        ],
        config.basename
    );
}
