// assets
import { IconDashboard, IconTrophy, IconDiamond, IconHistory, IconDice } from '@tabler/icons';
// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    acceptedChains: [-1],
    children: [
        {
            id: 'default',
            title: 'Home',
            type: 'item',
            url: '/',
            icon: IconDashboard,
            breadcrumbs: false,
            acceptedChains: [-1]
        },
        {
            id: 'rewards',
            title: 'Rewards',
            type: 'item',
            url: '/rewards',
            icon: IconDiamond,
            breadcrumbs: false,
            acceptedChains: [25, 137]
        }
    ]
};

export default dashboard;
